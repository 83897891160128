import { useEffect } from 'react';

import useAccount from '@api/queries/useAccount';
import useVehicle from '@api/queries/useVehicle';
import useScript, { Status } from '@hooks/useScript';
import { useConfig } from '@components/ConfigProvider';
import { useFeatureFlags } from '@components/FeatureFlags';

declare global {
  interface Window {
    embeddedservice_bootstrap: {
      settings: {
        language: string;
        orgId: string;
      };
      prechatAPI: {
        setHiddenPrechatFields: (userId: Record<string, string>, vin: Record<string, string>) => void;
      };
      init: (orgId: string, deploymentName: string, url: string, options: Record<string, string>) => void;
    };
  }
}

function LoadSalesforceChat() {
  const config = useConfig();
  const salesforceOrgId = config.get('SALESFORCE_ORG_ID') as string;
  const salesforceUrl = config.get('SALESFORCE_URL') as string;
  const salesforceScrtUrl = config.get('SALESFORCE_SCRT_URL') as string;
  const salesforceLibUrl = config.get('SALESFORCE_LIB_URL') as string;
  const salesforceDeploymentName = config.get('SALESFORCE_DEPLOYMENT_NAME') as string;

  const { salesforceChat: isSalesforceChatEnabled } = useFeatureFlags();

  const { data: account } = useAccount();
  const { data: vehicle } = useVehicle();

  const initSalesforceChat = () => {
    try {
      const embeddedserviceBootstrap = window.embeddedservice_bootstrap;
      embeddedserviceBootstrap.settings.language = 'en_US';

      window.addEventListener('onEmbeddedMessagingReady', (e) => {
        embeddedserviceBootstrap.prechatAPI.setHiddenPrechatFields(
          { IDMUserId: account?._id || '' },
          { vin: vehicle?.vin || '' },
        );
      });

      embeddedserviceBootstrap.init(salesforceOrgId, salesforceDeploymentName, salesforceUrl, {
        scrt2URL: salesforceScrtUrl,
      });

      console.log('Salesforce chat has been initiated successfully');
    } catch (err) {
      console.error('Failed to initiate Salesforce chat', err);
    }
  };

  const scriptUrl = isSalesforceChatEnabled ? salesforceLibUrl : undefined;
  const scriptStatus = useScript(scriptUrl);
  const isScriptReady = scriptStatus === Status.Ready;
  const isChatInitiated = !!window.embeddedservice_bootstrap?.settings?.orgId;

  useEffect(() => {
    if (isSalesforceChatEnabled && !isChatInitiated && isScriptReady) {
      initSalesforceChat();
    }
  }, [isSalesforceChatEnabled, isChatInitiated, isScriptReady, account, vehicle]);

  return null;
}

export default LoadSalesforceChat;
