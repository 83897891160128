import React from 'react';
import { Box, Checkbox, FormControlLabel, FormGroup, Collapse, Stack, Typography } from '@mui/material';
import { useSelector } from '@xstate/react';
import { useFlowMachine, useFlowMachineContextContent } from '../../Router/RouterContext';
import Button from '@app/components-lib/components/Button';
import Markdown from '@components/Markdown';
import { isBoolean, isNull } from 'lodash';

const scaleOfGrey = 500;
const CameraOptAgreement = () => {
  const {
    cameraTNCagree,
    cameraTNCdecline,
    cameraTNCHeader,
    cameraTNCSuccessAgreed,
    cameraTNCSuccessDeclined,
    cameraTNCError,
    commonWebContent: { saveLabel },
  } = useFlowMachineContextContent();

  const flowMachine = useFlowMachine();
  const termsAndConditions = useSelector(flowMachine, (state) => state.context.termsAndConditions);
  const agreedToCameraTNC = useSelector(flowMachine, (state) => state.context.agreedToCameraTNC);
  const cameraTNCApiStatus = useSelector(flowMachine, (state) => state.context.cameraTNCApiStatus);

  const checkBoxStyle = {
    '& svg': {
      width: '1.5rem',
      height: '1.5rem',
      borderRadius: 'unset',
      border: '1px solid',
      borderColor: (theme) => theme.palette.grey[scaleOfGrey],
      strokeWidth: '2px',
    },
  };

  const textStyles = {
    textAlign: 'center',
  };

  return (
    <>
      <Collapse in={cameraTNCApiStatus === 'idle'} unmountOnExit>
        <Stack gap={2} alignItems="center">
          <Typography variant="h4" align="center">
            {cameraTNCHeader}
          </Typography>

          <iframe height={500} width="60%" src={termsAndConditions?.agreementTemplateUrl} title={'Terms'} />

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  sx={checkBoxStyle}
                  checked={isBoolean(agreedToCameraTNC) && agreedToCameraTNC}
                  onClick={() => flowMachine.send({ type: 'setAgreedToCameraTNC', value: true })}
                />
              }
              label={cameraTNCagree}
            />
            <FormControlLabel
              control={
                <Checkbox
                  sx={checkBoxStyle}
                  checked={isBoolean(agreedToCameraTNC) && !agreedToCameraTNC}
                  onClick={() => flowMachine.send({ type: 'setAgreedToCameraTNC', value: false })}
                />
              }
              label={cameraTNCdecline}
            />
          </FormGroup>
          <Button disabled={isNull(agreedToCameraTNC)} sx={{ width: '60%' }} onClick={() => flowMachine.send('submit')}>
            {saveLabel}
          </Button>
        </Stack>
      </Collapse>

      <Collapse in={cameraTNCApiStatus === 'success agreed'} unmountOnExit>
        <Box sx={textStyles}>
          <Markdown>{cameraTNCSuccessAgreed}</Markdown>
        </Box>
      </Collapse>

      <Collapse in={cameraTNCApiStatus === 'success declined'} unmountOnExit>
        <Box sx={textStyles}>
          <Markdown>{cameraTNCSuccessDeclined}</Markdown>
        </Box>
      </Collapse>

      <Collapse in={cameraTNCApiStatus === 'error'} unmountOnExit>
        <Box sx={textStyles}>
          <Markdown>{cameraTNCError}</Markdown>
        </Box>
      </Collapse>
    </>
  );
};

export default CameraOptAgreement;
