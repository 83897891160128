import { assign, send, raise } from 'xstate';
import { ComponentRoutes } from '../../../types';
import { ComponentFlowStateConfig, ComponentActions, ShowAuthFormFlowContext, CameraTNCContext } from '../Types';
import { FlowEventName } from '../../../flowTypes';
import { ActionTypes } from '../../../flowSessionStorage';
import { SubscribedPackageInfo } from '../../../../manageSubscription/Types';
import { getAgreementTemplate } from '@app/components-lib/services/cps';
import { AgreementType } from '@cv/portal-cps-lib/agreements/agreement-service/enums';
import {
  getServicePreference,
  createServicePreference,
  updateServicePreference,
} from '@cv/portal-cps-lib/preferences/service-preferences';
import { options } from '../../../../services/commonService';
import { searchDefaultServicePreference } from '@cv/portal-cps-lib';

const preparePrefsToUpdate = (preferencesData, optIn) => {
  return preferencesData.reduce((acc, pref) => {
    if (
      pref.preferenceName === '5010_InVehicleCameraConsent' ||
      pref.preferenceName === '5009_InVehicleCameraConsent'
    ) {
      pref.optIn = optIn;
      acc.push(pref);
    }
    return acc;
  }, []);
};

export const confirmOrderFlow: ComponentFlowStateConfig<ShowAuthFormFlowContext & CameraTNCContext> = () => ({
  initial: 'pre',
  id: ComponentRoutes.confirmOrder,
  states: {
    pre: {
      always: [{ target: 'shouldShowAuthForm', cond: 'isAuthCodeFunctionality' }, { target: 'idle' }],
      entry: assign(({ flowSessionStorage }) => ({
        showDesignatedDealerMessage: flowSessionStorage?.subscribedPackages.some(
          (pkg: SubscribedPackageInfo) => pkg.designatedRefundChannel === 'DEALER',
        ),
      })),
    },
    shouldShowAuthForm: {
      entry: 'setLoading',
      invoke: {
        id: 'getAccountDetails',
        src: ({ subscriptionProps }) =>
          subscriptionProps.getAccountDetails(
            subscriptionProps.locale,
            subscriptionProps.userDetails.userId,
            subscriptionProps.accessToken,
          ),
        onDone: [
          {
            id: 'hideForm',
            target: 'idle',
            cond: 'isAuthFormHidden',
            actions: ['unsetLoading', 'hideForm'],
          },
          {
            id: 'error',
            target: 'idle',
            cond: 'isAuthFormError',
            actions: ['unsetLoading', 'showForm', 'setError'],
          },
          {
            target: 'idle',
            actions: ['unsetLoading', 'showForm'],
          },
        ],
        onError: {
          target: 'idle',
          actions: ['unsetLoading', 'hideForm'],
        },
      },
    },

    showWithCameraTNC: {
      entry: [
        'setLoading',
        assign({
          withCamera: (context) =>
            Boolean(context.subscriptionProps?.vehicleDetails?.additionalFeatures?.inVehicleCameraEquipped),
          agreedToCameraTNC: () => null,
          cameraTNCApiStatus: () => 'idle',
        }),
      ],
      invoke: {
        id: 'getAgreementWithCameraTemplate',
        src: (context) =>
          context.queryClient.fetchQuery(['termsAndConditionsWithCamera'], () =>
            getAgreementTemplate(context.subscriptionProps, AgreementType.In_Vehicle_Camera_Disclosure),
          ),
        onDone: {
          actions: [assign({ termsAndConditions: (_, event) => event.data }), 'unsetLoading'],
        },
        onError: {
          actions: [assign({ withCamera: false }), 'unsetLoading'],
        },
      },
      on: {
        setAgreedToCameraTNC: {
          actions: 'setCameraTNCEnabled',
        },
        submit: 'sendOptInPreference',
        [FlowEventName.NAVIGATE_FORWARD]: { target: 'completed' },
      },
    },

    sendOptInPreference: {
      entry: ['setLoading'],
      invoke: {
        id: 'checkOptAndSave',
        src: async (context) => {
          let preferencesToUpdate = [];
          try {
            const preferencesData = await getServicePreference(
              context.subscriptionProps.userDetails.userId,
              context.subscriptionProps.vehicleDetails.vehicleId,
              options(context.subscriptionProps),
            );

            preferencesToUpdate = preparePrefsToUpdate(
              preferencesData.data.servicePreferences,
              context.agreedToCameraTNC,
            );
          } catch (e) {
            if (e.data.code === 'PREF_VEH_USER_NOT_FOUND') {
              const defaultPrefs = await searchDefaultServicePreference({
                ...options(context.subscriptionProps),
                data: {
                  telematicsProgramId: context.subscriptionProps.vehicleDetails.telematicsProgramId,
                },
              });

              preferencesToUpdate = preparePrefsToUpdate(defaultPrefs.data, context.agreedToCameraTNC);

              await createServicePreference({
                ...options(context.subscriptionProps),
                data: {
                  servicePreferences: preferencesToUpdate,
                  telematicsProgramId: context.subscriptionProps.vehicleDetails.telematicsProgramId,
                  userId: context.subscriptionProps.userDetails.userId,
                  vehicleId: context.subscriptionProps.vehicleDetails.vehicleId,
                  vin: context.subscriptionProps.vehicleDetails.vin,
                },
              });
              return;
            }
            throw e;
          }

          const requestOptions = options(context.subscriptionProps);
          requestOptions.headers['If-Match'] = '*';
          await updateServicePreference({
            ...requestOptions,
            data: {
              servicePreferences: preferencesToUpdate,
              telematicsProgramId: context.subscriptionProps.vehicleDetails.telematicsProgramId,
              userId: context.subscriptionProps.userDetails.userId,
              vehicleId: context.subscriptionProps.vehicleDetails.vehicleId,
              vin: context.subscriptionProps.vehicleDetails.vin,
            },
          });
        },
        onDone: {
          actions: [
            'unsetLoading',
            assign((ctx) => ({ cameraTNCApiStatus: ctx.agreedToCameraTNC ? 'success agreed' : 'success declined' })),
          ],
        },
        onError: {
          actions: ['unsetLoading', assign({ cameraTNCApiStatus: 'error' })],
        },
      },
      on: {
        [FlowEventName.NAVIGATE_FORWARD]: { target: 'completed' },
      },
    },

    idle: {
      always: [{ target: 'showWithCameraTNC', cond: 'vehicleHaveCamera' }],
      entry: [
        raise({
          type: 'PUSH_HISTORY',
          data: { componentRoute: ComponentRoutes.confirmOrder },
        }),
        raise({
          type: 'RESET_FLOW_LAST_PAGE',
        }),
      ],
      on: {
        [FlowEventName.CONFIRM]: { target: 'confirmed' },
      },
    },
    confirmed: {
      entry: [
        'resetState',
        'clearCache',
        (context) => context.subscriptionProps.onDone?.(),
        (context) => context.subscriptionProps.getSubscriptionInfo?.(context.subscriptionProps),
      ],
      on: {
        [FlowEventName.NAVIGATE_FORWARD]: { target: 'completed' },
      },
    },
    completed: {
      entry: [
        'resetState',
        'clearCache',
        (context) => context.subscriptionProps.onDone?.(),
        (context) => context.subscriptionProps.getSubscriptionInfo?.(context.subscriptionProps),
        (context) => context.subscriptionProps.goBackToPortal?.(),
      ],
    },
  },
});

export const actions: ComponentActions<ShowAuthFormFlowContext & CameraTNCContext> = {
  resetState: send({ type: FlowEventName.SET_SESSION_STORAGE, action: { type: ActionTypes.ResetState } }),

  clearCache: (context) => {
    context.queryClient.clear();
    context.clearSubscriptionPropsCache();
  },

  showForm: assign({ showAuthCodeForm: true }),
  hideForm: assign({ showAuthCodeForm: false }),
  setError: assign({ isAuthStatusError: true }),
  setCameraTNCEnabled: assign({
    agreedToCameraTNC: (_, event) => event.value,
  }),
};
