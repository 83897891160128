import React from 'react';
import { useApi } from '@api';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, useModal } from '@app/components-lib';
import { Box, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { ActiveCriteria } from '@cv/portal-cps-lib/vehicle/vehicle-management/enums';
import { SearchVehiclePayload } from '@cv/portal-cps-lib/vehicle/vehicle-management/models/vehicle';
import { VIN_REGEX, VIN_SIZE } from '@components/AccountEnrollment/constants';
import { VinLookupProps } from '@components/VinLookup/Types';
import SearchIcon from '@mui/icons-material/Search';
import Modal from '@lib-components/NewModal/Modal';

const VinLookupForm = ({ labels }: VinLookupProps) => {
  const api = useApi();
  const { isShown, toggleModal } = useModal();

  const { vinInputPlaceholderLabel, invalidVinErrorMsg, vinRegexErrorMsg, checkVinLabel, vinSearchErrorMsg, dialogOk } =
    labels;

  const vinLookupSchema = z.object({
    vin: z.string().min(VIN_SIZE, invalidVinErrorMsg.primary).regex(VIN_REGEX, vinRegexErrorMsg.primary),
  });

  type VinLookupData = z.infer<typeof vinLookupSchema>;

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<VinLookupData>({
    mode: 'onChange',
    resolver: zodResolver(vinLookupSchema),
  });

  const { mutate: vinSearchMutation } = useMutation(
    async (request: VinLookupData) => {
      const response = await api.searchVehicle(request);
      return response.data;
    },
    {
      onSuccess: (data) => {
        if (data.length > 0) {
          // TODO: Show eligible packages section covered as part of different ticket
        }
      },
      onError: () => {
        toggleModal();
      },
    },
  );

  const onSubmit = async (data: VinLookupData) => {
    const searchVehicleReqPayload: SearchVehiclePayload = { vin: data.vin, activeCriteria: ActiveCriteria.All };
    vinSearchMutation(searchVehicleReqPayload);
  };

  return (
    <Box mt={4} mb={5}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid xs={12} md={8}>
            <TextField
              {...register('vin')}
              id={'vin'}
              name={'vin'}
              placeholder={vinInputPlaceholderLabel.primary}
              error={!!errors.vin}
              helperText={errors.vin?.message}
              sx={{
                width: '100%',
                backgroundColor: 'white',
              }}
              inputProps={{
                maxLength: VIN_SIZE,
              }}
            />
          </Grid>
          <Grid xs={12} md={4}>
            <Button
              type="submit"
              disabled={!isValid}
              startIcon={<SearchIcon />}
              sx={{ width: { xs: '100%', md: 'auto' } }}
            >
              {checkVinLabel.primary}
            </Button>
          </Grid>
        </Grid>
      </form>
      <Modal
        open={isShown}
        onClose={toggleModal}
        primaryControl={
          <Button
            fullWidth
            onClick={() => {
              toggleModal();
            }}
          >
            {dialogOk.primary}
          </Button>
        }
      >
        <Typography>{vinSearchErrorMsg.primary}</Typography>
      </Modal>
    </Box>
  );
};

export default VinLookupForm;
