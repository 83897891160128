import React from 'react';
import { Box } from '@mui/material';
import { ContentfulRichText } from '@cv/webframework-react-components';

import './RichTextContainer.css';

export type RichTextContainerProps = {
  content: object;
  primaryColorText?: boolean;
  textAlignment?: 'left' | 'right' | 'center' | 'justify';
};

const RichTextContainer = ({ content, textAlignment = 'left', primaryColorText }: RichTextContainerProps) => {
  const { asset, richText } = extractRichTextAndAsset(content);

  return (
    <Box
      className={`RichTextContainer text-${textAlignment}`}
      sx={(theme) => ({
        color: primaryColorText ? theme.palette.primary.main : 'inherit',
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
      })}
    >
      {asset && <img src={asset.data.target.fields.file.url} alt={asset.data.target.fields.title} />}
      <ContentfulRichText document={richText} />
    </Box>
  );
};

const extractRichTextAndAsset = (richText: any) => {
  const asset = richText.content.find((item: any) => item.tag === 'embedded-asset-block');
  const filteredContent = richText.content.filter((item: any) => item.tag !== 'embedded-asset-block');

  return { asset, richText: { ...richText, content: filteredContent } };
};

export default RichTextContainer;
