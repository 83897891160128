import React from 'react';
import { Box } from '@mui/material';
import VinLookup from '@components/VinLookup/VinLookup';
import { generateLabelsObjects, getDictionariesContent } from '@utils/labels';
import ContentfulComponent from '@app/types/ContentfulComponent';
import { getAssets, ServerAsset } from '@utils/assets';
import ContentRenderer from '@components/ContentRenderer';

interface VinLookupWrapperProps {
  contentSections: Array<ContentfulComponent>;
}

const VinLookupWrapper = ({ contentSections }: VinLookupWrapperProps) => {
  const labelDictionary = getDictionariesContent(contentSections);
  const labels = generateLabelsObjects(labelDictionary);

  const assets = getAssets(contentSections)?.map(({ key, asset }) => ({ key, asset } as ServerAsset));

  return (
    <Box>
      <VinLookup labels={labels} assets={assets} />

      <ContentRenderer name={'learn-about-vin-container'} content={contentSections} />
    </Box>
  );
};

export default VinLookupWrapper;
