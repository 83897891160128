import React from 'react';
import { scroller } from 'react-scroll';
import { Box, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import VinLookupForm from '@components/VinLookup/VinLookupForm';
import { VinLookupProps } from '@components/VinLookup/Types';

const VinLookup = ({ labels, assets }: VinLookupProps) => {
  const {
    vinLookupHeader,
    vinLookupSubheader,
    whatIsVinLabel,
    whatIsVinSectionId,
    whereToFindVinLabel,
    whereToFindVinSectionId,
  } = labels;
  const headerBackground = assets.find((a) => a.key === 'vin-lookup-header-background');

  const scrollToSection = (sectionId: string) => {
    scroller.scrollTo(sectionId, {
      duration: 1500,
      smooth: 'easeInOutQuint',
    });
  };

  return (
    <Box
      data-testid="header-box"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '40vh',
        backgroundPosition: 'center',
        color: 'white',
        backgroundImage: `url("${headerBackground?.asset.file.url}")`,
        zIndex: -1,
        backgroundSize: { xs: 'cover', md: 'cover' },
      }}
    >
      <Box
        sx={{
          padding: 4,
          width: { xs: '100%', md: '70%' },
          margin: 'auto',
          zIndex: 1,
        }}
      >
        <Typography variant="h5" mb={1}>
          {vinLookupHeader.primary}
        </Typography>
        <Typography variant="h6">{vinLookupSubheader.primary}</Typography>

        <VinLookupForm labels={labels} assets={assets} />

        <Grid container spacing={2} alignItems="center">
          <Grid xs={6} md={8} onClick={() => scrollToSection(whatIsVinSectionId.primary)}>
            {whatIsVinLabel.primary}
          </Grid>
          <Grid
            xs={6}
            md={4}
            sx={{ textAlign: { xs: 'right', md: 'left' } }}
            onClick={() => scrollToSection(whereToFindVinSectionId.primary)}
          >
            {whereToFindVinLabel.primary}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default VinLookup;
